







































































































import {Component, Vue} from 'vue-property-decorator';
import MenuItemView, {MenuItem} from './AppSidebarProfileMenuItem.vue';
import {auth} from '@/store/modules/auth';

@Component({
    components: {
        MenuItemView,
    },
})
export default class AppSidebarProfile extends Vue {
    private get user() {
        return auth.context(this.$store).state.user;
    }

    private get name(): string {
        return this.user?.name || '';
    }

    private menuOpen = false;

    private openMenu() {
        this.menuOpen = true;
    }

    private closeMenu() {
        this.menuOpen = false;
    }

    private toggleMenu() {
        if (this.menuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    private get menuItems(): MenuItem[] {
        return [
            {
                label: 'Выход',
                icon: 'logout',
                route: {
                    name: 'logout',
                },
            },
        ];
    }

    private documentClickHandler(e: MouseEvent) {
        if (!this.menuOpen) {
            return;
        }

        if (this.$el === e.target || this.$el.contains(e.target as Node)) {
            return;
        }

        this.closeMenu();
    }

    private mounted() {
        document.addEventListener('click', this.documentClickHandler);
    }

    private destroyed() {
        document.removeEventListener('click', this.documentClickHandler);
    }
}
