

































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {RawLocation, Route} from 'vue-router';

export interface MenuItem {
    label: string;
    icon?: string;
    exactActive?: boolean;
    route: RawLocation;
    activeCallback?: (route: Route) => boolean;
    count?: number | (() => number);
    children?: MenuItem[];
}

@Component({
    components: {
        MenuItemView: () => import('./AppSidebarMenuItem.vue'),
    },
})
export default class extends Vue {
    @Prop({required: true})
    private item!: MenuItem;

    private get route(): RawLocation {
        return this.item.route;
    }

    private get label(): string {
        return this.item.label;
    }

    private get icon(): string | null {
        return this.item.icon || null;
    }

    private get children(): MenuItem[] | null {
        return this.item.children || null;
    }

    private get exactActive(): boolean {
        return !!this.item.exactActive;
    }

    private get isShowChildren(): boolean {
        if (!this.children) {
            return false;
        }

        return !!this.children.find((c) => this.itemIsActive(c));
    }

    private get isActive(): boolean {
        return this.itemIsActive(this.item);
    }

    private get count(): number | null {
        if (typeof this.item.count === 'undefined') {
            return null;
        }

        if (typeof this.item.count === 'function') {
            return this.item.count();
        }

        return this.item.count;
    }

    private itemIsActive(item: MenuItem): boolean {
        const route = this.$router.resolve(this.route).route;

        if (typeof item.activeCallback !== 'undefined') {
            return item.activeCallback(route);
        }

        if (this.children) {
            return false;
        }

        return this.$route.name === route.name;
    }
}
