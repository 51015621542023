








































import {Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation} from 'vue-router';

export interface MenuItem {
    label: string;
    icon: string;
    route: RawLocation;
}

@Component
export default class extends Vue {
    @Prop({required: true})
    private item!: MenuItem;

    private get routeTo(): RawLocation {
        return this.item.route;
    }

    private get label(): string {
        return this.item.label;
    }

    private get icon(): string {
        return this.item.icon;
    }
}
