
























































































import {Component, Vue} from 'vue-property-decorator';
import AppSidebarProfile from './AppSidebarProfile.vue';
import AppSidebarMenu from './AppSidebarMenu.vue';

@Component({
  components: {
    AppSidebarProfile,
    AppSidebarMenu,
  },
})
export default class AppSidebar extends Vue {
}
